import React, { useState, useEffect } from 'react';
// import { API } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
// import songData from './data/tempSongData.json'
import SongRow from './SongRow.js'
import FinalCountdown from './FinalCountdown.js';

const client = generateClient();



const songsByTotal = `
    query songsByTotal {
        songsByTotal(nomination_year: "2024", sortDirection: DESC, filter: {approved: {eq: true}}) {
        items {
            song
            album
            band
            donated_total
            approved
            donation_link
            id
            createdAt
            updatedAt
        }
        }
    }
`


const SongTable = ( closingDate ) => {
    const [songData, setSongData] = useState([]);

    const loadSongData = async() => {
/*         const { data } = await client.graphql(
            graphqlOperation(songsByTotal)
        );
 */        
        const { data } = await client.graphql({
            query: songsByTotal
        });

 //       console.log(data);

        setSongData(data?.songsByTotal?.items)
    }

    useEffect(() => {
        loadSongData();
    }, []); 
    

    return (
      <section id="songTable" className="section">
        <div className='container'>
            <h2>The 2024 Ceol Kids song list!</h2>
            <p>On Friday May 24, the Ceol Kids band will play the top 25 tunes that you help select for us by making donations! The donation period ends Friday April 26th at midnight, so make sure you get your picks selected before then. Click the clipboard icon to copy the donation link and forward to friends and family to help move it into the top 25! </p>
            <FinalCountdown closingDate = {closingDate.closingDate}/>
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th>Song</th>
                        <th>Band</th>
                        <th>Album</th>
                        <th>Donations</th>
                        <th></th>
                        <th className='text-center'>Donation Link</th>
                    </tr>
                </thead>
                <tbody>
                    {
                       songData?.map((song, index) => <SongRow songName={`${index + 1}. ${song.song}`} band={`${song.band}`} album={`${song.album}`} donationTotal={`${song.donated_total.toFixed(2)}`} paymentLink={`${song.donation_link}`} rowkey={`${song.id}`} createdAt={`${song.createdAt}`} updatedAt={`${song.updatedAt}`} />)
                    }
 
                </tbody>
            </table>
        </div>
      </section>
    );
  }
  
  export default SongTable;