import React, { useState } from 'react';
// import { API } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { generateClient, post } from 'aws-amplify/api';
import { createSongs, updateSongs } from '../graphql/mutations';

const client = generateClient();




const Nominate = () => {

    let [songName, setSongName] = useState('');
    let [albumName, setAlbumName] = useState('');
    let [bandName, setBandName] = useState('');
    let [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        let validationMessage = '';

        // Sanitize input
        const sanitizeInput = (input) => {
        const regex = /<(.|\n)*?>/g;
        input = input.replace(regex, '');
        return input;
        }
        
        songName = sanitizeInput(songName);
        bandName = sanitizeInput(bandName);
        albumName = sanitizeInput(albumName);
        email = sanitizeInput(email);

        // Add validation for string length
        if (songName.length > 75 || bandName.length > 75 || albumName.length > 75 || email.length > 75) {
            validationMessage += 'You seem to have entered some invalid input, can you try again? ';
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            validationMessage = 'Please enter a valid email address. ';
           // console.log(validationMessage);
//            return;
        }
          
        if (!songName.trim() || !bandName.trim()) {
            validationMessage += 'You need to tell us both a song name and band name.';
          //  console.log(validationMessage);
 //           return;
        }

        if (validationMessage.trim()) {
            validationMessage = 'Whoops, you didn\'t fill out all the info. ' + validationMessage;
            setMessage(validationMessage);
            return;
        }
    
        try {
            const input = {
                song: songName,
                band: bandName,
                album: albumName,
                nominated_by: email,
                nomination_year: '2024',
                approved: false,
                donated_total: 0,
                donation_link_id: 'pending',
                nominated_on: new Date().toISOString()
            };
 //           const addSongDbResults = await client.graphql(graphqlOperation(createSongs, { input }));
            const addSongDbResults = await client.graphql({
                query: createSongs,
                variables: { input }
            });
            // Clear form after submitting
            setSongName('');
            setAlbumName('');
            setBandName('');
            setEmail('');
           // console.log(addSongDbResults);
            const songInfo = addSongDbResults.data.createSongs;
         //   console.log(songInfo.id);
            setMessage('Congrats! ' + songInfo.song + ' by ' + songInfo.band + ' was nominated successfully! Remember, it needs to be approved before it appears on the list. Approval usually takes less than 48 hours. While you wait, you can always nominate another song or make a donation to one of the songs already on the list!');

/*      OLD CODE FOR STRIPE CALL

            const apiName = 'addStripeProduct'; // replace this with your api name.
            const path = '/stripe'; //replace this with the path you have configured on your API
            const myInit = {
            body: {
                "song_name": songInfo.song,
                "band_name": songInfo.band,
                "song_id": songInfo.id
            }, // replace this with attributes you need
            headers: {} // OPTIONAL
            };

            const addStripeProductResults = await post(apiName, path, myInit); */
         //   console.log(addStripeProductResults);


            // new post code for stripe update

            const restOperation = post({
                apiName: 'addStripeProduct',
                path: '/stripe',
                options: {
                  body: {
                    "song_name": songInfo.song,
                    "band_name": songInfo.band,
                    "song_id": songInfo.id
                    }
                }
              });
          
              const { body } = await restOperation.response;
              const addStripeProductResults = await body.json();
          
              console.log('POST call to make stripe product succeeded');
              console.log(addStripeProductResults);

            // end of new post code for stripe update

            const updateInput = {
                id: songInfo.id,
                donation_link: addStripeProductResults.paymentUrl,
                donation_link_id: addStripeProductResults.paymentLink
            };


         //   console.log(updateInput);


            const updateSongDbResults = await client.graphql({
                query: updateSongs,
                variables: { input: updateInput  }
            });

         //   console.log(updateSongDbResults);
 
            setMessage(<span>Congrats! {songInfo.song} by {songInfo.band} was nominated successfully! Remember, it needs to be approved before it appears on the list. Approval usually takes less than 48 hours. While you wait, you can always nominate another song or make a donation to one of the songs already on the list!. If you want to make a donation for {songInfo.song}  you can use this <a href={addStripeProductResults.paymentUrl} target="_blank" rel="noreferrer">link</a> to make a donation right now. </span>);


// TESTING PURPOSES ONLY, DELETE AFTER TEST IS SUCCESSFUL


/* const songId = req.body.product_id;
const newDonationAmount = req.body.amount;
const donationTime = req.body.processed_time;
const email = req.body.user_email;
const paymentId = req.body.payment_id;


            const TESTapiName = 'stripeWebhookApi'; // replace this with your api name.
            const TESTpath = '/webhook'; //replace this with the path you have configured on your API
            const TESTmyInit = {
            body: {
                "user_email": "jvardy@gmail.com",
                "amount": 50,
                "payment_id": "xyz",
                "product_id": songInfo.id,
                "processed_time": new Date().toISOString()
            }, // replace this with attributes you need
            headers: {} // OPTIONAL
            };

            const TestWebhook = await client.post(TESTapiName, TESTpath, TESTmyInit);
            console.log(TestWebhook);
// END OF TEST CODE
 */

        } catch (err) {
         //   console.log(err);
         //   console.log(err.errors[0].message)
            setMessage('Uh oh, something went wrong. It\'s not you, it\'s me. Here\'s some technical jargon about what happened. Let Julian at jvardy@gmail.com know and he\'ll try and sort it out: ' + err.message);
        }
    };
    
    return (
        <section>
            <div className='container' id='nominate'>
                <div className="jumbotron">
                    <img className = "left-block intro-image" src = "images/keytar.png" alt="Nominations" width="266" height="266" />
                    <h2>Song Nominations!</h2>
                    <p>Do you think you have the perfect song for the Ceol Kids band to play? Maybe you want to challenge us with a tricky song, or get us to play something that has personal meaning for you? We've had to play all kinds of crazy tunes over the years, including <a href='https://www.youtube.com/watch?v=dUxolLok198'>The Final Countdown</a>, <a href='https://www.youtube.com/watch?v=KOPeyax2V1I&list=PLfDYCqGmlHaT2fLPJrU6QZEUHfkE_9v9k&index=11'>Paradise By The Dashboard Light</a> and <a href='https://www.youtube.com/watch?v=5j6tBEN2SwQ&list=PLfDYCqGmlHaT2fLPJrU6QZEUHfkE_9v9k&index=18'>Panama</a>.
                    We'll do our best to perform any song that makes it into the top 25 in terms of total donations collected.  <strong>*Please note that new songs must be verified by the Ceol Kids team before appearing on the list.</strong> Verification usually takes less than 48 hours.</p>
                </div>
                    <form onSubmit={handleSubmit} className='add_form'>
                        <header>
                            <h2>Add Your Song!</h2>
                            <p name='nominate-message'>
                            {message ||
                                'Enter the song, album, and band name for the song you want to nominate!'}
                            </p>
                        </header>    
                        <div className='row'>
                            <div className='form-group col-lg-3'>
                                <input
                                    id='songName'
                                    name='songName'
                                    type='text'
                                    className='form-control'
                                    placeholder='Song Name'
                                    value={songName}
                                    onChange={(e) => setSongName(e.target.value)}
                                />
                            </div>
                            <div className='form-group col-lg-3'>
                                <input
                                    id='albumName'
                                    name='albumName'
                                    type='text'
                                    className='form-control'
                                    placeholder='Album Name'
                                    value={albumName}
                                    onChange={(e) => setAlbumName(e.target.value)}
                                />
                            </div>
                            <div className='form-group col-lg-3'>
                                <input
                                    id='bandName'
                                    name='bandName'
                                    type='text'
                                    className='form-control'
                                    placeholder='Band Name'
                                    value={bandName}
                                    onChange={(e) => setBandName(e.target.value)}
                                />
                            </div>
                            <div className='form-group col-lg-2'>
                                <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    className='form-control'
                                    placeholder='Your Email Address'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='form-group col-lg-1'>
                                <button type='submit' className='btn btn-primary'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
            </div>
        </section>
    );
    };
    
    export default Nominate;