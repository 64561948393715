import React from 'react';
import PostItem from './PostItem';
import postsData from './data/postsData.json'

const Jumbo = ( isClosed ) => {
    return (
      <section id="jumbo" className="section">
        <div className='container'>
        <div className="jumbotron">

          <h2 className="headline">
            <img className='left-block intro-image' src='images/HipKids-sm.png'  alt="Image of Rock Show" width="266" height="266"></img>
            
            {isClosed.isClosed ? <div>Ceol Kids 2025 Launches Saturday March 1</div> : <div>Ceol Kids 2025 Donation Drive is under way!</div>}
            <p></p>
          </h2>
           
           {isClosed.isClosed ? 
              <p>Start thinking of new great songs for the Ceol Kids band to play! We're opening up the site for donations on Saturday March 1st, with a kickoff party at the Cameron House. Last year, we played songs from Boston, P!NK, Megadeth, Ashley MacIssak, Brandi Carlile and many other, all chosen by our donors. And when the dust settled, we ended up with over $15,000 in donations for the first time ever! Thanks to our sponsors at Long and McQuade for the generous donation of a guitar and gift card for our raffle, as well as rental credits to help us maximize our donation total. And super props to the band (of 48 performers) for learning all the tunes and managing the switchovers seamlessly! Can't wait to see what you pick for us this year!</p>
              //<p>The setlist has been chosen! Check the list below to see the 25 songs that we'll be playing at the show. Lots of excitement right down to the wire. Now make sure to get your tickets and come out to the show at Lee's Palace on May 24th. It's going to be a wild one! And we're all very excited to have our first show at the historic Lee's Palace. Doors are at 8pm, get there early so you don't miss the opening song! Thanks to everyone who donated, nominated and promoted tunes for the setlist. Now we start learning and rehearsing the tunes you chose for us! We are so pumped to play a show for you again. Let's make it an extra great one! </p>         
           : 
              <p>Have you been dreaming up exciting new tunes for the Ceol Kids band to play? Time to pick your songs and get your donations in. The top 25 songs at midnight April 25th (Friday night) will be the ones we play at the show. And proceeds go to the Community Music Schools of Toronto, a fantastic organization making sure that all kids have access to an excellent music education. So check back often and make sure your selected tune stays in the top 25 so that the Ceol Kids band will play it at the show on Friday May 23, 2025 at Lee's Palace. We are so pumped to play a show for you again. Let's make it an extra great one!</p>
           }
  

       </div>
       <div className='row'>
       {
            postsData.map((post) => <PostItem postHeader={`${post.postHeader}`} postedBy={`${post.postedBy}`} postedOn={`${post.postedOn}`} postBody={`${post.postBody}`} />)
        }
        </div>
       </div>
      </section>
    );
  }
  
  export default Jumbo;